// @flow
import React from 'react';
import {useParams, Link} from 'react-router-dom';
import {Breadcrumb} from 'antd';
import GenreInfo from './GenreInfo'; 
const Genre = () => {
    const {id} = useParams();
    const breadcrumb = <Breadcrumb style={{ margin: '16px 0' }} separator='>'>
        <Breadcrumb.Item>
            <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
            <Link to={`/Genre/${id}`}>Genre</Link>
        </Breadcrumb.Item>
    </Breadcrumb>;
    return(
        <>
            {breadcrumb}
            <div className="site-layout-content">
                <GenreInfo genreId={id}/>
            </div>
            
        </>
    )
}

export default Genre;