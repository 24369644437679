// @flow
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Breadcrumb, Card, List} from 'antd';
import Loading from './Loading';
import settings from '../config.json';
const AllGenres = () => {
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [genres, setGenres] = useState([]);
    useEffect(()=>{
        let mounted =true;
        if(mounted){
            fetch(settings.baseUrl+`/v2/genres`)
            .then(res => res.json())
            .then((results)=> {
                setError(false);
                setGenres(results);
                setLoading(false);
            },(err)=>{
                setError(true);
            })
        }
        return () => mounted = false;
    },[]);
    const breadcrumb = <Breadcrumb style={{ margin: '16px 0' }} separator='>'>
        <Breadcrumb.Item>
            <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
            <Link to="/genres">Genres Listing</Link>
        </Breadcrumb.Item>
    </Breadcrumb>;
    if(isLoading){
        return <Loading control={<div className="site-layout-content"></div>}/>
    }
    else{
        if(error || (genres !== null && genres.error !== undefined && genres.error)){
            return(
                <>
                    {breadcrumb}
                    <div className="site-layout-content">
                        <Card title="Unexpected error" bordered={false}>
                            <div>{genres.message}</div>
                        </Card>
                    </div>
                </>
            );
        }
        else{
            return(
                <>
                    {breadcrumb}
                    <div className="site-layout-content">
                        <h2>Genres listing</h2>
                        <List itemLayout="vertical" size="default" 
                            pagination={{pageSize:30,showSizeChanger:false}}
                            dataSource={genres}
                            footer={
                                <div>Genres Listing</div>
                            }
                            renderItem={
                                genre => (
                                    <List.Item key={genre.id}>
                                        <List.Item.Meta 
                                            title={<a href={`/genres/${genre.id}`}>{genre.name}</a>}
                                        />
                                    </List.Item>
                                )
                            }
                        />
                    </div>
                </>
            );
        }
    }
};

export default AllGenres;