// @flow
import React, {useState, useEffect} from 'react';
import {Card} from 'antd';
import NovelsListing from './NovelsListing';
import settings from '../config.json';

const NovelInGenre = ({genreId}) => {
    const [error, setError] = useState(false);
    const [novels, setNovels] = useState([]);
    useEffect(()=>{
        let mounted =true;
        if(mounted){
            fetch(settings.baseUrl+`/v2/novelsingenre/${genreId}`)
            .then(res => res.json())
            .then((results)=> {
                setError(false);
                setNovels(results);
            },(err)=>{
                setError(true);
            })
        }
        return () => mounted = false;
    },[genreId]);

    if(error || (novels !== null && novels.error !== undefined && novels.error)){
        return(
            <Card title="Unexpected error" bordered={false}>
                <div>{novels.message}</div>
            </Card>
        );
    }
    else{
        return(
            <NovelsListing novels = {novels}/>
        );
    }
};

export default NovelInGenre;