// @flow
import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {Breadcrumb, Card} from 'antd';
import NovelsListing from './NovelsListing';
import Loading from './Loading';
import settings from '../config.json';
const AllNovels = () => {
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [novels, setNovels] = useState([]);
    useEffect(()=>{
        let mounted =true;
        if(mounted){
            fetch(settings.baseUrl+`/v2/novels`)
            .then(res => res.json())
            .then((results)=> {
                setError(false);
                setNovels(results);
                setLoading(false);
            },(err)=>{
                setError(true);
            })
        }
        return () => mounted = false;
    },[]);
    const breadcrumb = <Breadcrumb style={{ margin: '16px 0' }} separator='>'>
        <Breadcrumb.Item>
            <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
            <Link to="/novels">Novels Listing</Link>
        </Breadcrumb.Item>
    </Breadcrumb>;
    if(isLoading){
        return <Loading control={<div className="site-layout-content"></div>}/>
    }
    else{
        if(error || (novels !== null && novels.error !== undefined && novels.error)){
            return(
                <div className="site-layout-content">
                    <Card title="Unexpected error" bordered={false}>
                        <div>{novels.message}</div>
                    </Card>
                </div>
            );
        }
        else{
            return(
                <div className="site-layout-content">
                    <NovelsListing novels = {novels}/>
                </div>
            );
        }
    }
}

export default AllNovels;