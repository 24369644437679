// @flow
import React from 'react';
import {Layout} from 'antd';
import MobileLeftNav from './MobileLeftNav';
const {Header} = Layout;

const MobileSiteHeader = () => {
    return (
        <Header>
            <div style={{float:'left', color: 'white', marginRight:'20px'}}>
                <MobileLeftNav/>
            </div>
            <div style={{float:'right', color: 'white'}}>search icon to click on the right</div>
        </Header>
    );
};

export default MobileSiteHeader;