// @flow
import React, {useState, useEffect} from 'react';
import {Card} from 'antd';
import NovelInGenre from './NovelsInGenre';
import Loading from './Loading';
import settings from '../config.json';

const GenreInfo = ({genreId}) => {
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [genre, setGenre] = useState(null);
    useEffect(()=>{
        let mounted = true;
        if(mounted){
            fetch(settings.baseUrl+`/v2/genres/${genreId}`)
            .then(res => res.json())
            .then((results)=> {
                setError(false);
                setGenre(results);
                setLoading(false);
            },(err)=>{
                setError(true);
            })
        }
        return () => mounted = false;
    },[genreId]);
    if(isLoading){
        return <Loading control={<></>}/>;
    }
    else{
        if(error || (genre !== null && genre.error !== undefined && genre.error)){
            return(
                <Card title="Unexpected error" bordered={false}>
                    <div>{genre.message}</div>
                </Card>
            );
        }
        else{
            return(
                <Card title={genre !== null ? `${genre.name}` : ""} bordered={false}>
                    <NovelInGenre genreId={genreId}/>
                </Card>
            );
        }
    }
};

export default GenreInfo;