// @flow
import React,{useState, useEffect} from 'react';
import {List,Card} from 'antd';
import Loading from './Loading';
import settings from '../config.json';
const ChapsListing = ({novelId}) => {
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    useEffect(()=>{
        let mounted = true;
        if(mounted){
            fetch(settings.baseUrl+`/v2/chapInfo/${novelId}`)
            .then(res => res.json())
            .then((results)=> {
                setError(false);
                setData(results);
                setLoading(false);
            },(err)=>{
                setError(true);
            })
        }
        return () => mounted = false;
    },[novelId]);
    if(isLoading){
        return <Loading control={<></>}/>;
    }
    else{
        if(error || (data !== null && data.error !== undefined && data.error)){
            return(
                <Card title="Unexpected error" bordered={false}>
                    <div>{data.message}</div>
                </Card>
            );
        }
        else{
            if(data === null){
                return(
                    <Card title="Unexpected error" bordered={false}>
                    </Card>
                );
            }
            return(
                <List itemLayout="vertical" size="default" 
                pagination={{pageSize:1,showSizeChanger:false}}
                dataSource={data}
                footer={
                    <div>Chaps Listing</div>
                }
                renderItem={
                    page => ( page.chapInfo.map((chap)=>{
                                return (
                                    <List.Item key={`chap-${chap.sequence}`}>
                                        <a href={`/chaps/${novelId}/${chap.sequence}`}>Chap Sequence: {chap.sequence} - {chap.title}</a>
                                    </List.Item>
                                );
                        })
                    )
                }/>
            );
        }
    }
};
export default ChapsListing;