// @flow
import React, { useState } from 'react';
import {Drawer, Button} from 'antd';
import {MenuOutlined } from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';

const MobileLeftNav = () => {
    const {isAuthenticated, loginWithRedirect, logout} = useAuth0();
    const [visible, setVisible] = useState(false);
    const toggleDrawer = () => {
        setVisible(!visible);
    };
    
    const onClose = () => {
        setVisible(false);
    };

    return(
        <Button type='text' onClick={toggleDrawer}>
            <MenuOutlined style={{ fontSize: '24px', color: '#ffffff' }}/>
            <Drawer
                title='Truyen Chuong'
                placement="left"
                closable={false}
                onClose={onClose}
                visible={visible}
            >
                <p><Link to="/">Home</Link></p>
                <p><Link to="/genres">Genres</Link></p>
                <p><Link to="/novels">Novels</Link></p>
                <p><Link to="/authors">Authors</Link></p>
                {!isAuthenticated && (
                    <p><Link to="#" onClick={() => loginWithRedirect({})}>Log in</Link></p>
                )}
                {isAuthenticated && (
                    <p><Link to="#">Log Out</Link></p>
                )}
            </Drawer>
        </Button>
    );
};

export default MobileLeftNav;