// @flow
import React, {useState, useEffect} from 'react';
import {Menu} from 'antd';
import {Link} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import SubMenu from 'antd/lib/menu/SubMenu';
import settings from '../config.json';

const DesktopLeftNavBar = () => {
    const {isAuthenticated, loginWithRedirect} = useAuth0();
    const [error, setError] = useState(false);
    const [items, setItems] = useState([]);
    useEffect(()=>{
        let mounted = true;
        if(mounted){
            fetch(settings.baseUrl+"/v2/genres")
            .then(res => res.json())
            .then((results)=> {
                setError(false);
                setItems(results);
            },(err)=>{
                setError(true);
            })
        }
        return () => mounted = false;
    },[]);
    if(error || (items !== null && items.error !== undefined && items.error)){
        return(
            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
                <Menu.Item key="1"><Link to="/">Home</Link></Menu.Item>
                <Menu.Item key="3"><Link to="/novels">Novels</Link></Menu.Item>
                <Menu.Item key="4"><Link to="/authors">Authors</Link></Menu.Item>
                {!isAuthenticated && (
                    <Menu.Item key="5"><Link to="#" onClick={() => loginWithRedirect({})}>Log in</Link></Menu.Item>
                )}
            </Menu>
        );
    }
    else{
        return(
            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['0']}>
                <Menu.Item key="0"><Link to="/">Home</Link></Menu.Item>
                <SubMenu title="Genres">
                    {items.map(item => (
                        <Menu.Item key={item.id}>
                            <Link to={`/Genres/${item.id}`}>{item.name}</Link>
                        </Menu.Item>
                    ))}
                </SubMenu>
                <Menu.Item key="100"><Link to="/novels">Novels</Link></Menu.Item>
                <Menu.Item key="101"><Link to="/authors">Authors</Link></Menu.Item>
                {!isAuthenticated && (
                    <Menu.Item key="102"><Link to="#" onClick={() => loginWithRedirect({})}>Log in</Link></Menu.Item>
                )}
            </Menu>
        );
    }
    
};

export default DesktopLeftNavBar;