// @flow
import React from 'react';
import {Avatar,Dropdown,Menu} from 'antd';
import {Link} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';

const DesktopRightNavBar = () => {
    const {isAuthenticated, logout} = useAuth0();
    const menu = <Menu theme="light" mode="horizontal" style={{minWidth:150}}>
                    <Menu.Item key="1"><Link to="#">Profile</Link></Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="2">
                        <Link to="#" onClick={() => {logout({ returnTo: window.location.origin });}}>Log Out</Link>
                    </Menu.Item>
                </Menu>;
    return(
            <Dropdown overlay={menu}>
                <Avatar
                    style={{
                    backgroundColor: '#f56a00',
                    verticalAlign: 'middle',
                    cursor: 'pointer'
                    }}
                    size="large"
                    gap='4'
                >
                    L
                </Avatar>
                
            </Dropdown>
            
    );
};

export default DesktopRightNavBar;