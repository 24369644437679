// @flow
import React from 'react';
import useViewport from '../utils/useViewport';
import config from '../config.json';
import MobileSiteHeader from './MobileSiteHeader';
import DesktopSiteHeader from './DesktopSiteHeader';

const SiteHeader = () => {
    const {width} = useViewport();
    return (
        width <= config.mobile_width+config.width_margin ? <MobileSiteHeader/> : <DesktopSiteHeader/>
    );
};

export default SiteHeader;