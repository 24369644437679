// @flow
import React from 'react';
import { Spin } from 'antd';

const Loading = ({control}) => {
    return (
        <Spin tip='Loading...'>
            {control}
        </Spin>
        
        
    );
};

export default Loading;