// @flow
import React from 'react';
import {List} from 'antd';
const AuthorsListing = ({authors}) => {
    return(
        <List itemLayout="vertical" size="default" 
        pagination={{pageSize:30,showSizeChanger:false}}
        dataSource={authors}
        footer={
            <div>Author Listing</div>
        }
        renderItem={
            author => (
                <List.Item key={author.id}>
                    <List.Item.Meta 
                        title={<a href={`/authors/${author.id}`}>{author.name}</a>}
                    />
                    {author.details}
                </List.Item>
            )
        }/>
    );
};
export default AuthorsListing;