// @flow
import React,{useState, useEffect} from 'react';
import {useParams, Link} from 'react-router-dom';
import {Breadcrumb, Card, Button} from 'antd';
import Loading from './Loading';
import settings from '../config.json';
const Chap = () => {
    const {chapId,novelId} = useParams();
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [chap, setChap] = useState(null);
    const [novel, setNovel] = useState(null);
    useEffect(()=>{
        const getChap = async() => {
            await fetch(settings.baseUrl+`/v2/chaps/${novelId}/${chapId}`)
            .then(res => res.json())
            .then((results)=> {
                setError(false);
                setChap(results);
                setLoading(false);
            },(err)=>{
                setError(true);
            });
            
            await fetch(settings.baseUrl+`/v2/novels/${novelId}`)
            .then(res => res.json())
            .then((results)=> {
                setNovel(results);
                setLoading(false);
            });
        }
        let mounted = true;
        if(mounted){
            getChap();
        }
        return () => mounted = false;
    },[novelId, chapId]);
    const breadcrumb = <Breadcrumb style={{ margin: '16px 0' }} separator='>'>
        <Breadcrumb.Item>
            <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
            <Link to="/novels">Novels Listing</Link>
        </Breadcrumb.Item>
        {
            (!error && chap !== null && chap.novel !== undefined && chap.novel !== '') ? 
                (<Breadcrumb.Item>
                    <Link to={`/novels/${novelId}`}>{chap.novel}</Link>
                </Breadcrumb.Item>) : <></>
        }
        {
            (!error && chap !== null && chap.sequence !== undefined) ? 
                (<Breadcrumb.Item>
                    <Link to={`/chaps/${novelId}/${chap.sequence}`}>Chap {chap.sequence}: {chap.chapTitle}</Link>
                </Breadcrumb.Item>) : <></>
        }
        
    </Breadcrumb>;
    let title = '';
    let content = <></>;
    if(isLoading)
    {
        return (
            <Loading control={<div className="site-layout-content"></div>}/>
        );
    }
    else{
            if(chap === null){
                title = 'Unexpected error';
                content = <></>;
            }
            else
            {
                if(error && chap.error !== undefined && chap.error){
                    title = 'Unexpected error';
                    content = <p><strong>{chap.message}</strong></p>;
                }
                if(!error && !chap.error){
                    title = `Chap ${chap.sequence}: ${chap.chapTitle}`;
                    content = <>
                        <div dangerouslySetInnerHTML={{__html:chap.content}} />
                        <div>{
                            parseInt(chapId) > 1 ? <Button type="primary" shape="round" href={`/chaps/${novelId}/${parseInt(chapId)-1}`}>Prev Chap</Button> : <></>
                            }
                            {
                                (novel !== null && !novel.error && chapId < novel.totalChaps) ? <Button type="primary" shape="round" href={`/chaps/${novelId}/${parseInt(chapId)+1}`}>Next Chap</Button> : <></>
                            }
                        </div>
                    </>
                }
            }
        return(
            <>
                {breadcrumb}
                <div className="site-layout-content">
                    <Card title={title}>
                        {content}
                    </Card>
                </div>
                
            </>
        );
    }
}

export default Chap;