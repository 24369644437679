// @flow
import React from 'react';
import SiteHeader from '../components/SiteHeader';
import Home from '../components/Home';
import Profile from '../components/Profile';
import AllNovels from '../components/AllNovels';
import AllAuthors from '../components/AllAuthors';
import Novel from '../components/Novel';
import Genre from '../components/Genre';
import AllGenres from '../components/AllGenres';
import Author from '../components/Author';
import Chap from '../components/Chap';
import { Layout  } from 'antd';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

const { Content, Footer } = Layout;
const App = () => {
    return (
            <Router>
                <Layout className='layout'>
                    <SiteHeader/>
                    <Content style={{ padding: '0 50px' }}>
                        <Switch>
                            <Route path="/profile">
                                <Profile/>
                            </Route>
                            <Route path="/chaps/:novelId/:chapId" component={Chap}>
                            </Route>
                            <Route path="/novels/:id">
                                <Novel/>
                            </Route>
                            <Route path="/novels">
                                <AllNovels/>
                            </Route>
                            <Route path="/authors/:id">
                                <Author/>
                            </Route>
                            <Route path="/authors">
                                <AllAuthors/>
                            </Route>
                            <Route path="/Genres/:id">
                                <Genre/>
                            </Route>
                            <Route path="/Genres">
                                <AllGenres/>
                            </Route>
                            <Route path="/">
                                <Home/>
                            </Route>
                        </Switch>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>Truyen Chuong ©2020</Footer>
                </Layout>
            </Router>
    );
    
}

export default App;