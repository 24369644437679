// @flow
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Breadcrumb} from 'antd';
import {Card} from 'antd';
import AuthorsListing from './AuthorsListing';
import settings from '../config.json';
const AllAuthors = () => {
    const [error, setError] = useState(false);
    const [authors, setAuthors] = useState([]);
    useEffect(()=>{
        let mounted =true;
        if(mounted){
            fetch(settings.baseUrl+`/v2/authors`)
            .then(res => res.json())
            .then((results)=> {
                setError(false);
                setAuthors(results);
            },(err)=>{
                setError(true);
            })
        }
        return () => mounted = false;
    },[]);
    const breadcrumb = <Breadcrumb style={{ margin: '16px 0' }} separator='>'>
        <Breadcrumb.Item>
            <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
            <Link to="/authors">Authors Listing</Link>
        </Breadcrumb.Item>
    </Breadcrumb>;
    if(error || (authors !== null && authors.error !== undefined && authors.error)){
        return(
            <>
                {breadcrumb}
                <div className="site-layout-content">
                    <Card title="Unexpected error" bordered={false}>
                        <div>{authors.message}</div>
                    </Card>
                </div>
            </>
        );
    }
    else{
        return(
            <>
                {breadcrumb}
                <div className="site-layout-content">
                    <AuthorsListing authors={authors}/>
                </div>
            </>
        );
    }
};

export default AllAuthors;