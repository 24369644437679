// @flow
import React,{useState, useEffect} from 'react';
import {useParams, Link} from 'react-router-dom';
import {Breadcrumb,Card} from 'antd';
import AuthorInfo from './AuthorInfo';
import AuthorNovels from './AuthorNovels';
import Loading from './Loading';
import settings from '../config.json';
const Author = () => {
    const {id} = useParams();
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [author, setAuthor] = useState(null);
    useEffect(()=>{
        let mounted = true;
        if(mounted){
            fetch(settings.baseUrl+`/v2/authors/${id}`)
            .then(res => res.json())
            .then((results)=> {
                setError(false);
                setAuthor(results);
                setLoading(false);
            },()=>{
                setError(true);
            })
        }
        return () => mounted = false;
    },[id]);
    const breadcrumb = <Breadcrumb style={{ margin: '16px 0' }} separator='>'>
        <Breadcrumb.Item>
            <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
            <Link to="/authors">Authors Listing</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
            <Link to={`/authors/${id}`}>{author !== null ? author.name : ""}</Link>
        </Breadcrumb.Item>
    </Breadcrumb>;
    if(isLoading){
        return <Loading control={<div className="site-layout-content"></div>}/>
    }
    else{
        let novels = [];
        if(error || author === null){
            return <Card title='Unexpected error'>Please try again!</Card>
        }
        if(!error && author !== null){
            novels = author.novels;
            return(
                <>
                    {breadcrumb}
                    <div className="site-layout-content">
                        <AuthorInfo author={author}/>
                        <AuthorNovels novels={novels}/>
                    </div>
                    
                </>
            )
        }
    }
};

export default Author;