// @flow
import React from 'react';
import {List} from 'antd';
import {SolutionOutlined } from '@ant-design/icons';
import IconText from './IconText';
const AuthorNovels = ({novels}) => {
    return(
        <List itemLayout="vertical" size="default" 
        pagination={{pageSize:30,showSizeChanger:false}}
        dataSource={novels}
        footer={
            <div>Author's Novels Listing</div>
        }
        renderItem={
            novel => (
                <List.Item key={novel.id}
                    actions={[
                        <IconText icon={SolutionOutlined} text={`Total Chaps: ${novel.totalChaps}`} key="list-vertical-totalChaps-o"/>,
                    ]}
                >
                    <List.Item.Meta 
                        title={<a href={`/novels/${novel.id}`}>{novel.name}</a>}
                    />
                </List.Item>
            )
        }/>
    );
};

export default AuthorNovels;