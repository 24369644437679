// @flow
import React from 'react';
import {Layout} from 'antd';
import LinkWithImg from './LinkWithImg';
import DesktopLeftNavBar from './DesktopLeftNavBar';
import DesktopRightNavBar from './DesktopRightNavBar';
import SearchBox from './SearchBox';
import {useAuth0} from '@auth0/auth0-react';

const DesktopSiteHeader = () => {
    const {isAuthenticated} = useAuth0();
    const {Header} = Layout;
    const rightSide = isAuthenticated ? <DesktopRightNavBar/> : <></>;
    return (
        <Header>
            <div className="logo">
                <LinkWithImg altText='Truyen Chuong' imgSrc={process.env.PUBLIC_URL + '/imgs/truyenchuong.ico'} to='/' />
            </div>
            <div className="desktop-left-navbar">
                <DesktopLeftNavBar/>
            </div>
            <div className='desktop-search-box'>
                <SearchBox/>
            </div>
            <div className="desktop-right-navbar">
                {rightSide}
            </div>
        </Header>
    );
};
export default DesktopSiteHeader;