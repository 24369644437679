// @flow
import React from 'react';
import {List} from 'antd';
import {SolutionOutlined,SmileOutlined } from '@ant-design/icons';
import IconText from './IconText';
const NovelsListing = ({novels}) => {
    return(
        <List itemLayout="vertical" size="default" 
        pagination={{pageSize:30,showSizeChanger:false}}
        dataSource={novels}
        footer={
            <div>Novels Listing</div>
        }
        renderItem={
            novel => (
                <List.Item key={novel.novelId}
                    actions={[
                        <IconText icon={SolutionOutlined} text={`Total Chaps: ${novel.totalChaps}`} key="list-vertical-totalChaps-o"/>,
                        <IconText icon={SmileOutlined} text={`Total Read: ${novel.totalRead}`} key="list-vertical-totalRead-o"/>
                    ]}>
                    <List.Item.Meta 
                        title={<a href={`/novels/${novel.novelId}`}>{novel.name}</a>}
                    />
                    {novel.author}
                </List.Item>
            )
        }/>
    );
};
export default NovelsListing;