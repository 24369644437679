// @flow
import React from 'react';
import {Card} from 'antd';

const AuthorInfo = ({author}) => {
    if(author === null || (author !== null && author.error !== undefined && author.error)){
        return(
            <Card title="Unexpected error" bordered={false}>
                <div>{author!== null? author.message: ''}</div>
            </Card>
        );
    }
    else {
        return (
            <Card title={author !== null ? `${author.name}` : ""} bordered={false}>
                <div><b>Details: </b>{author.details}</div> <br/>
            </Card>
        );
        
    }
};
export default AuthorInfo;