// @flow
import React from 'react';
import {AutoComplete,Input} from 'antd';

const SearchBox = () => {
    const {Search} = Input;
    //add autocomple 
    return (
        <AutoComplete style={{width:350}}
            onSearch={()=> console.log("on search")}
            onSelect={()=> console.log("on select")}
        >
            <Search
                placeholder="input search text"
                enterButton="Search"
                size="large"
                onSearch={value => console.log(value)}
            />
        </AutoComplete>
    );
};

export default SearchBox;