// @flow
import React,{useState} from 'react';
import {Card, Button} from 'antd';
import {TagOutlined,ReadOutlined,DislikeOutlined,LikeOutlined} from '@ant-design/icons';
import IconText from './IconText';
import settings from '../config.json';
const NovelInfo = ({novel}) => {
    const [like, setLike] = useState(novel.like);
    const [dislike,setDislike] = useState(novel.dislike);
    const likeNovel = () => {
        fetch(settings.baseUrl+'/v2/novels/like', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({'id':novel.id})
        }).then(async response => {
            const data = await response.json();
            if (response.status >= 200 && response.status < 300) {
                setLike(data);
            }
        }).catch(err => err);
    };
    const dislikeNovel = () => {
        fetch(settings.baseUrl+'/v2/novels/dislike', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({'id':novel.id})
        }).then(async response => {
            const data = await response.json();
            if (response.status >= 200 && response.status < 300) {
                setDislike(data);
            }
        }).catch(err => err);
    };
    if(novel === null || (novel !== null && novel.error !== undefined && novel.error)){
        return(
            <Card title="Unexpected error" bordered={false}>
                <div>{novel!== null? novel.message:""}</div>
            </Card>
        );
    }
    else{
        
        const genres = novel.genres.map((g)=> {return <span key={`span-${g.id}`}><IconText icon={TagOutlined} text={`${g.name}`} key={`list-genre-${g.id}`}/> </span>});
        const footer = <div>
            <span><IconText icon={ReadOutlined} text={novel.totalRead} key="list-total-read-0"/> </span>
            <span><Button type='text' icon={<LikeOutlined/>} onClick={likeNovel}>{like}</Button></span>
            <Button type='text' icon={<DislikeOutlined/>} onClick={dislikeNovel}>{dislike}</Button>
        </div>;
        return(
            //todo: display novel's img
            <Card title={novel !== null ? `${novel.name}` : ""} bordered={false}>
                <div><b>Genres: </b>{genres}</div> <br/>
                <div><b>Author: </b><a href={`/authors/${novel.authorId}`}>{novel.authorName}</a></div><br/>
                <div><b>Total Chaps: </b><span>{novel.totalChaps}</span></div><br/>
                <div><b>Descriptions: </b><span>{novel.desc}</span></div><br/>
                <div><b>Status: </b><span>{novel.status}</span></div><br/>
                {footer}
            </Card>
        );
    }
};

export default NovelInfo;