// @flow
import React,{useState, useEffect} from 'react';
import {useParams, Link} from 'react-router-dom';
import {Breadcrumb,Card} from 'antd';
import NovelInfo from './NovelInfo';
import ChapListing from './ChapsListing';
import Loading from './Loading';
import settings from '../config.json';
const Novel = () => {
    const {id} = useParams();
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [novel, setNovel] = useState(null);
    useEffect(()=>{
        let mounted = true;
        if(mounted){
            fetch(settings.baseUrl+`/v2/novels/${id}`)
            .then(res => res.json())
            .then((results)=> {
                setError(false);
                setNovel(results);
                setLoading(false);
            },()=>{
                setError(true);
            })
        }
        return () => mounted = false;
    },[id]);
    const breadcrumb = <Breadcrumb style={{ margin: '16px 0' }} separator='>'>
        <Breadcrumb.Item>
            <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
            <Link to="/novels">Novels Listing</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
            <Link to={`/novels/${id}`}>{novel !== null ? novel.name : ""}</Link>
        </Breadcrumb.Item>
    </Breadcrumb>;

    if(isLoading){
        return <Loading control={<div className="site-layout-content"></div>}/>
    }
    else{
        if(error || novel === null){
            return <Card title='Unexpected error'>Please try again!</Card>
        }
        if(!error && novel !== null){
            return(
                <>
                    {breadcrumb}
                    <div className="site-layout-content">
                        <NovelInfo novel={novel}/>
                        <ChapListing novelId={id}/>
                    </div>
                    
                </>
            )
        }
    }
}

export default Novel;