// @flow
import React from 'react';
import {Breadcrumb} from 'antd';
import {Link} from 'react-router-dom';

function Profile() {
    const breadcrumb = <Breadcrumb style={{ margin: '16px 0' }} separator='>'>
        <Breadcrumb.Item>
            <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
            <Link to="/profile">Profile</Link>
        </Breadcrumb.Item>
    </Breadcrumb>;
    return (
        <>
            {breadcrumb}
            <div className="site-layout-content">
                <div>Profile</div>
            </div>
        </>
    );
}

export default Profile;